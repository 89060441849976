<template>
    <div class="loginContainer">
        <img class="loginBackImg" src="../../assets/loginBack.png">
        <div class="loginContentView">
            <img class="loginContentImg" src="../../assets/loginContent.png">
            <div class="loginContentContainer">
                <div class="content">
                    <div class="left">
                        <div class="loginTitle">AM Arena</div>
                        <div class="loginInputView">
                            <el-input type="text" class="loginInput" placeholder="Email" v-model="form.account_name"></el-input>
                        </div>
                        <div class="loginInputView">
                            <el-input type="password" class="loginInput" placeholder="Password" v-model="form.account_pwd"></el-input>
                        </div>
                        <div class="loginInputView">
                            <el-select type="text" class="loginInput" placeholder="Identity" v-model="form.type" style="width:100%">
                                <el-option value="学生" label="Student"></el-option>
                                <el-option value="讲师" label="Lecturer"></el-option>
                                <el-option value="助教" label="Teaching Assistant"></el-option>
                            </el-select>
                        </div>
                        <div class="loginBtn" @click="submit">Login</div>
                    </div>
                    <div class="right">
                        <img src="../../assets/logo.png" class="logo">
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { loginByEducator,loginByStudent,loginByEmployee } from '../../api/index'
import { setUserId,setUserName,setUserType } from '../../utils/store'

export default {
    name: 'Login',
    data() {
        return {
            form: {
                account_name: '',
                account_pwd: '',
                type:''
            }
        }
    },
    methods: {
        submit() {
            if(!this.form.account_name){
                this.$message.error('Please input email')
                return
            }

            if(!this.form.account_pwd){
                this.$message.error('Please input password')
                return
            }

            if(this.form.type==='讲师'){
                loginByEducator(this.form.account_name,this.form.account_pwd).then(res=>{
                    if(res.data.code==0){
                        this.$message({
                            message:'login success',
                            type:'success'
                        })
                        setUserId(res.data.data._id.$id)
                        setUserName(res.data.data.educator_first_name+res.data.data.educator_last_name)
                        setUserType('educator')
                        this.$router.push('/teacherlist')
                    }else{
                        this.$message.warning('login fail')
                    }
                })
            }else if(this.form.type==='学生'){
                loginByStudent(this.form.account_name,this.form.account_pwd).then(res=>{
                    if(res.data.code==0){
                        this.$message({
                            message:'login success',
                            type:'success'
                        })
                        setUserId(res.data.data._id.$id)
                        setUserType('student')
                        setUserName(res.data.data.student_lastName+res.data.data.student_givenName)
                        this.$router.push('/studentlist')
                    }else{
                        this.$message.warning('login fail')
                    }
                })
            }else if(this.form.type==='助教'){
                loginByEmployee(this.form.account_name,this.form.account_pwd).then(res=>{
                    if(res.data.code==0){
                        this.$message({
                            message:'login success',
                            type:'success'
                        })
                        setUserId(res.data.data._id.$id)
                        setUserType('employee')
                        setUserName(res.data.data.user_lastName+res.data.data.user_givenName)
                        this.$router.push('/employeelist')
                    }else{
                        this.$message.warning('login fail')
                    }
                })
            }

        }
    }
}
</script>

<style lang="less">
.el-select-dropdown__item.selected {
    color: rgb(243,152,0);
    font-weight: 700;
}
.loginContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .loginBackImg{
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -2;
        top: 0;
        left: 0;
    }
    .loginContentView{
        width: 70%;
        height: 70%;
        background: rgba(255,255,255);
        border-radius: 28px;
        position: relative;
        .loginContentContainer{
            width: 100%;
            position: absolute;
            z-index: 3;
            .content{
                display: flex;
                width: 90%;
                margin: 0 auto;
                .left{
                    width: 50%;
                    position: relative;
                    .loginTitle{
                        font-size: 4vh;
                        margin-top:10vh;
                        text-align: center;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #002A38;
                    }
                    .loginInputView{
                        margin: 5vh auto;
                        width: 72%;
                    }
                    .loginBtn{
                        width: 72%;
                        height: 5vh;
                        line-height: 5vh;
                        text-align: center;
                        margin:6vh auto;
                        background: #E74646;
                        border-radius: 10px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #FFFFFF;
                        font-size: 2vh;
                        cursor: pointer;
                    }
                    .loginBtn:hover{
                            opacity: 0.8;
                        }
                    .el-input__inner{
                        border-radius: 10px!important;
                        /* border: none; */
                        height: 5vh;
                        line-height: 5vh;
                        font-size: 2vh;
                        padding: 0 22px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #888888;
                    }
                    .el-input.is-active .el-input__inner, .el-input__inner:focus {
                        border-color: rgb(243,152,0);
                        outline: 0;
                    }
                    .el-input__icon{
                        line-height: 5vh;
                    }
                }
                .right{
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .logo{
                        width: 60%;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
        .loginContentImg{
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
        }
    }
}

</style>
